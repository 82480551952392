import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import firebase from 'firebase/app'

//import VConsole from 'vconsole'

Vue.config.productionTip = false

var firebaseConfig = {
  apiKey: "AIzaSyBNQcn9PTaDpTTCQ6xGERkCa6ZoCtK2FGI",
  authDomain: "hirota-dental-reservation.firebaseapp.com",
  projectId: "hirota-dental-reservation",
  storageBucket: "hirota-dental-reservation.appspot.com",
  messagingSenderId: "781694736344",
  appId: "1:781694736344:web:2dcc8638aaa7cd1184c4ad"
};
firebase.initializeApp(firebaseConfig);

// vconsole
//new VConsole()

new Vue({
  router,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
