<template>
  <v-card width="650px" class="mx-auto mt-5">
    <v-container>
      <v-card-title>
        <h1 v-if="user.manage_id.length == 0" class="display-1">予約確認</h1>
        <h1 v-else class="display-1">予約変更確認</h1>

      </v-card-title>
      <v-card-subtitle v-if="user.manage_id.length == 0" >下記内容で登録します。修正する場合は「修正する」ボタンを押してください。</v-card-subtitle>
      <v-card-subtitle v-else >下記内容へ変更します。修正する場合は「修正する」ボタンを押してください。</v-card-subtitle>

      <v-card-text>日時: {{st_tm}}〜</v-card-text>

      <v-row>
        <v-col>
          <v-card-text v-if="user.manage_id.length == 0">
            <v-text-field v-model="this.service_type[user.service_type - 1]" label="診療希望内容" outlined readonly />
            <v-text-field v-model="user.name" label="お名前" outlined readonly/>
            <v-text-field v-model="user.phone" label="電話番号（固定電話・携帯番号）" outlined readonly/>
            <v-text-field v-model="user.birthday" label="生年月日" outlined readonly/>
            <v-text-field v-model="this.genders[user.gender - 1]" label="性別" outlined readonly />
            <v-text-field v-model="this.medical_type[user.medical_type - 1]" label="当院での受診" outlined readonly />
            <v-text-field v-model="this.doctor[user.doctor - 1]" label="ご希望担当医" outlined readonly />
            <v-text-field v-model="user.card_no" label="診察券番号" outlined readonly/>
            <v-textarea v-model="user.note" label="担当医へのメッセージ" outlined readonly/>
          </v-card-text>
          <v-card-text v-else>
            <v-text-field v-model="this.service_type[user.service_type - 1]" label="診療希望内容" outlined readonly />
          </v-card-text>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn color="error" large block class="font-weight-bold" v-on:click="$router.back()" :disabled="loading">修正する</v-btn>
        </v-col>
        <v-col>
          <v-btn v-if="user.manage_id.length == 0" color="success" large block class="font-weight-bold" v-on:click="submit" :loading="loading">登録する</v-btn>
          <v-btn v-else color="success" large block class="font-weight-bold" v-on:click="submit" :loading="loading">変更する</v-btn>
        </v-col>
      </v-row>
      <!-- Snackbar -->
      <div>
        <v-snackbar
          v-model="snackbarFlag"
          color="error"
          :timeout="snackbarTimeout"
          :multi-line="true"
          top
        >
          {{ snackbarText }}
          <template v-slot:action="{ attrs }">
            <v-btn
              text
              v-bind="attrs"
              @click="snackbarFlag = false"
            >
              <v-icon
              right
              >
              mdi-close-circle-outline
            </v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    user: Object
  },
  data() {
    return {
      snackbarFlag: false,
      snackbarTimeout: 3000,
      snackbarText: '',
      loading: false,
      service_type: ["虫歯", "クリーニング", "ホワイトニング相談", "歯科検診", "歯周病", "つめ物・かぶせ物", "口臭", "親知らず", "入れ歯・義歯"],
      medical_type: ["初診", "通院中", "前回から半年以上経過"],
      doctor: ["院長", "副院長", "前回と同じ歯科医師"],
      genders: ["男性", "女性"],
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.loading) {
      next(false)
    } else {
      next()
    }
  },
  computed: {
    st_tm: {
      get: function() {
        return this.user.st_tm.format('YYYY年MM月DD日 HH:mm')
      }
    }
  },
  methods: {
    confirmSave(event) {
      const answer = window.confirm("編集中のものは保存されませんが、よろしいですか？")
      if (answer) {
        next()
      } else {
        next(false)
      }
    },
    submit() {
      this.loading = true
      const me = this;
      
      const publicKeyStr = process.env.VUE_APP_CRYPTO_PUBLIC_KEY.replace(/\\n/g, '\n')
      const publicKey = KEYUTIL.getKey(publicKeyStr)

      const name = KJUR.crypto.Cipher.encrypt(this.user.name, publicKey, 'RSA')
      const send_data = {
        uuid: this.user.uuid,
        manage_id: this.user.manage_id,
        token: this.user.token,
        service_type: this.user.service_type,
        name: name,
        phone: this.user.phone,
        gender: this.user.gender,
        birthday: this.user.birthday,
        medical_type: this.user.medical_type,
        doctor: this.user.doctor,
        st_tm: this.user.st_tm,
        note: this.user.note,
        card_no: this.user.card_no,
        isClient: this.user.isClient
      }
      
      const params = new URLSearchParams()
      params.append('user_data', JSON.stringify(send_data))

      var targetURL = (this.user.manage_id.length == 0 ? process.env.VUE_APP_REGIST_SCHEDULE_API : process.env.VUE_APP_UPDATE_SCHEDULE_API)
      axios.post(targetURL, params)
        .then(response => {
          me.loading = false

          if (response.status == 200) {
            me.$router.replace('/done')
          } else {
            me.loading = false
            me.showSnackbar("登録に失敗しました。")
          }

        }).catch(error => {
          console.log(error);
          me.loading = false
          me.showSnackbar("登録に失敗しました。")
      });
    },
    showSnackbar(text) {
      this.snackbarFlag = true
      this.snackbarText = text
    }
  }
}
</script>