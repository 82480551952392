<template>
  <div>
    <router-view v-bind:user.sync="user"></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {
        uuid: 'PC',
        manage_id: '',
        token: '',
        service_type: '',
        name: '',
        phone: '',
        gender: {},
        birthday: '',
        medical_type: '',
        doctor: '',
        st_tm: '',
        note: '',
        card_no: '',
        isClient: false
      },
    }
  },
  created () {
    this.initializeLiff()
  },
  methods: {
    initializeLiff() {
      var me = this
      this.user.isClient = liff.isInClient()

      // 編集用ID
      const manage_id = this.$route.query.manage_id

      if (manage_id != undefined) {
        this.user.manage_id = manage_id
      }

      if (this.user.isClient) {
        liff.init(
          {
            liffId: process.env.VUE_APP_LIFF_ID
          },
          data => {
            if (liff.isLoggedIn()) {
              // IDトークン取得
              me.user.token = liff.getAccessToken()

            } else {
              this.loginAction()
            }
          },
          err => {
            console.log('LIFF initialization failed', err)
          }
        )
      }
    },
    loginAction() {
      // ログインまだ
      if (!liff.isLoggedIn()) {
        liff.login()
      }
    }
  }
}
</script>