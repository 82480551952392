<template>
  <v-card width="650px" class="mx-auto mt-5">
    <v-container>
      <v-card-title>
        <h1 v-if="user.manage_id.length == 0" class="display-1">予約フォーム</h1>
        <h1 v-else class="display-1">予約変更フォーム</h1>
      </v-card-title>
      
      <v-card-text v-if="user.manage_id.length == 0">予約日時: {{st_tm}}〜</v-card-text>
      <v-card-text v-else>予約変更後日時: {{st_tm}}〜</v-card-text>
      <v-form ref="send_data">
        <v-row>
          <v-col>
              <v-select
                v-model="user.service_type"
                item-text="label"
                item-value="value"
                :items="service_type"
                label="診療希望内容"
                outlined
                :rules="[requiredselect]"
              ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="user.manage_id.length == 0">
          <v-col>
              <v-text-field v-model="user.name" label="お名前" outlined :rules="[required]"/>
              <v-text-field v-model="user.phone" label="電話番号（固定電話 or 携帯番号）" outlined :rules="[required, phoneRules.regex]"/>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="user.birthday"
                    label="生年月日"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    :rules="[required]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="user.birthday"
                  ref="picker"
                  :max="new Date().toISOString().substr(0, 10)"
                  locale="jp-ja"
                  :day-format="date => new Date(date).getDate()"
                  min="1900-01-01"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
          </v-col>
        </v-row>
        <v-row v-if="user.manage_id.length == 0">
          <v-col>
              <v-select
                v-model="user.gender"
                item-text="label"
                item-value="value"
                :items="genders"
                label="性別"
                outlined
                :rules="[requiredselect]"
              ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="user.manage_id.length == 0">
          <v-col>
              <v-select
                v-model="user.medical_type"
                item-text="label"
                item-value="value"
                :items="medical_type"
                label="当院での受診"
                outlined
                :rules="[requiredselect]"
              ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="user.manage_id.length == 0">
          <v-col>
              <v-select
                v-model="user.doctor"
                item-text="label"
                item-value="value"
                :items="doctor"
                label="ご希望担当医"
                outlined
                :rules="[requiredselect]"
              ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="user.manage_id.length == 0">
          <v-col>
              <v-text-field v-model="user.card_no" label="診察券番号（通院したことのある方）" outlined />
              <v-textarea v-model="user.note" label="担当医へのメッセージ" outlined />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn large block class="font-weight-bold" v-on:click="$router.back()">戻る</v-btn>
          </v-col>
          <v-col>
            <v-btn color="info" large block class="font-weight-bold" v-on:click="submit">確認</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-card>

</template>

<script>
export default {
  props: {
    user: Object
  },
  data() {
    return {
      success: false,
      required: value => !!value || "必ず入力してください",
      requiredselect: value =>  value.length > 0 || "選択してください",
      phoneRules: {
        regex: v => /^[0-9]*$/.test(v) || '数字のみ入力してください'
      },
      menu: false,
      service_type: [{ label: "虫歯", value: "1"}, { label: "クリーニング", value: "2"}, {label: "ホワイトニング相談", value: "3"}, {label: "歯科検診", value: "4"}, { label: "歯周病", value: "5"}, { label: "つめ物・かぶせ物", value: "6"}, { label: "口臭", value: "7"}, { label: "親知らず", value: "8"}, { label: "入れ歯・義歯", value: "9"}],
      medical_type: [{ label: "初診", value: "1"}, { label: "通院中", value: "2"}, {label: "前回から半年以上経過", value: "3"}],
      doctor: [{ label: "院長", value: "1"}, {label: "副院長", value: "2"}, { label: "前回と同じ歯科医師", value: "3"}],
      genders: [{ label: "男性", value: "1"}, { label: "女性", value: "2"}, { label: "答えたくない", value: "3"}],
    }
  },
  computed: {
    st_tm: {
      get: function() {
        return this.user.st_tm.format('YYYY年MM月DD日 HH:mm')
      }
    }
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
  methods: {
    submit() {
      if (this.$refs.send_data.validate()) {
        this.success = true
        this.$router.push('/confirm')
      } else {
        this.success = false
      }
    }
  }
}
</script>