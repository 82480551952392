<script>
import firebase from 'firebase/app'
require('firebase/auth')

export default {
  data() {
    return {
      snackbarFlag: false,
      snackbarTimeout: 3000,
      snackbarText: '',
      required: value => !!value || "必ず入力してください",
      show: false,
      manager: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    signIn() {
      var me = this

      if (this.$refs.send_data.validate()) {
        firebase.auth().signInWithEmailAndPassword(this.manager.email, this.manager.password).then(
          user => {
            me.$router.push('/manager/main')
          },
          err => {
            me.showSnackbar('ログインID、パスワードが間違っています。')
          }
        )
      }
    },
    showSnackbar(text) {
      this.snackbarFlag = true
      this.snackbarText = text
    }
  }
}
</script>

<template>
  <v-card width="650px" class="mx-auto mt-5">
    <v-container>
      <v-card-title>
        <h1 class="display-1">ヒロタ歯科クリニック 管理画面ログイン</h1>
      </v-card-title>

      <v-form ref="send_data">
        <v-row>
          <v-col>
              <v-text-field v-model="manager.email" label="メールアドレス" outlined :rules="[required]"/>
              <v-text-field v-model="manager.password" :type="show ? 'text' : 'password'" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" label="パスワード" outlined :rules="[required]" @click:append="show = !show"/>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn color="info" large block class="font-weight-bold" v-on:click="signIn">ログイン</v-btn>
          </v-col>
        </v-row>
      </v-form>
      <!-- Snackbar -->
      <div>
        <v-snackbar
          v-model="snackbarFlag"
          color="info"
          :timeout="snackbarTimeout"
          :multi-line="true"
          top
        >
          {{ snackbarText }}
          <template v-slot:action="{ attrs }">
            <v-btn
              text
              v-bind="attrs"
              @click="snackbarFlag = false"
            >
              <v-icon
              right
              >
              mdi-close-circle-outline
            </v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-container>
  </v-card>
</template>